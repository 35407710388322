import { singleSignOn } from '@/axios'

export default {
  login (payload) {
    return singleSignOn().post('merchant/login', payload)
  },
  checkUser (paylod) {
    return singleSignOn().post('merchant/check', paylod)
  }
}
